.STREAMERCARD {
  height: 300px;
  width: 650px;
  display: flex;
  margin: 10px;
}

.STREAMERCARD #card_container {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  display: flex;
  border-radius: 5px;
}

.STREAMERCARD .left_container {
  height: 100%;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.STREAMERCARD .right_container {
  height: 100%;
  width: calc(100% - 180px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.STREAMERCARD #profile_picture {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 160px;
  width: 160px;
  border-radius: 5px;
  border: 1px solid white;
  border-bottom: 2px solid white;
}


.STREAMERCARD #username {
  margin: 0px;
  font-size: 2.9em;
  text-decoration: none;
}

.STREAMERCARD #bio {
  margin: 0px;
  max-height: 100px;
  font-size: 1.0em;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.STREAMERCARD .text {
  margin: 0px;
  font-size: 1.3em;
}

.STREAMERCARD .num_games {
  margin-right: 10px;
  font-size: 1.3em;
}

.STREAMERCARD .num_games_container {
  width: 100%;
  display: flex;
}

.STREAMERCARD .favorites_container {
  width: calc(100% - 14px);
  border-left: 2px solid white;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.STREAMERCARD .favorites_text {
  margin: 0px;
  margin-left: 10px;
}
