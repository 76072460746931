.LANDINGPAGE {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LANDINGPAGE .title {
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
  font-size: 5em;
}

.LANDINGPAGE .title .bold {
  font-weight: bold;
}

.LANDINGPAGE .description {
  font-size: 1.5em;
  font-weight: 300;
  margin: 5px;
}

.LANDINGPAGE #search_button {
  width: 500px;
  height: 50px;
  border: 2px solid white;
  background: none;
  margin-top: 20px;
  font-size: 1.5em;
}

.LANDINGPAGE #search_button:hover {
  background-color: white;
}

.LANDINGPAGE .boundary_line {
  width: 300px;
  height: 1px;
  background-color: white;
  margin: 30px 0px;
}

.LANDINGPAGE .about_data_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.LANDINGPAGE .about_data_item_container {
  height: 200px;
  width: 400px;
  margin: 10px;
}


.LANDINGPAGE .fade_in_background_color {
  transition-property: background-color;
  transition-duration: 2s;
}


.LANDINGPAGE .data_title {
  margin: 0px;
}

.LANDINGPAGE .about_data_icon {
  margin-top: 20px;
  margin-bottom: 7px;
  height: 60px;
}

.LANDINGPAGE .data_panel_description {
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
