/* General -------------------------------------------------------------------*/
/*
  - Used for all <TagInputArea/> components
*/

.TAGINPUTAREA {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}


.TAGINPUTAREA .title {
  font-size: 2em;
  margin: 0px;
}

.TAGINPUTAREA .input_method_container {
  width: calc(100% - 1px);
  border-right: 1px solid white;
  min-height: 60px;
  margin-bottom: 20px;
}

.TAGINPUTAREA .input_method_container:hover {
  width: calc(100% - 1px);
  border-right: 1px solid white;
}

.TAGINPUTAREA #start_input_button {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 2em;
  padding: 0px;
  margin: 0px;
  border-radius: 0px;
  border: 0px;
  margin-left: 10px;
}

.TAGINPUTAREA .selected_tags_container {
  display: flex;
  max-width: calc(100% - 120px);
  flex-wrap: wrap;
}


.TAGINPUTAREA .boundary_line {
  height: 5px;
  width: 100%;
}

.TAGINPUTAREA .top_row_container_select_active {
  width: calc(100% - 1px);
  min-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.TAGINPUTAREA .top_row_container_select_off {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}


.TAGINPUTAREA .tags_content_container {
  width: 100%;
  display: flex;
  min-height: 100px;
  flex-wrap: wrap;
  padding-top: 15px;
  justify-content: space-around;
}

.TAGINPUTAREA .tag_spacing_container {
  margin: 10px;
  width: 17%;
}

.TAGINPUTAREA .tag_spacing_selected_options {
  margin: 5px 0px 5px 0px;
}

/* Tag -----------------------------------------------------------------------*/

.TAG {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0;
  margin-right: 5px;
  margin-left: 5px;
  padding-left: 10px;
  padding-right: 5px;
}

.no_border {
  min-height: 40px;
  min-width: 120px;
}

.with_border {
  min-height: 38px;
  border: 1px solid white;
  min-width: 120px;
}

.TAG .text {
  font-size: 1.5em;
  margin: 0px;
  text-align: left;
}

.TAG #close_button {
  font-size: 1.7em;
  margin: 0px;
  cursor: pointer;
}

.TAG .star_close_container {
  display: flex;
  margin-left: 20px;
  align-items: center;
}

.TAG #star {
  font-size: 2em;
  margin: 0px 5px 0px 0px;
  cursor: pointer;
}


/* MultipleChoice ------------------------------------------------------------*/

.MULTIPLECHOICE {
  width: 100%;
}

.MULTIPLECHOICE #add_options_button {
  border-color: white;
  margin-left: 10px;
  margin-right: 20px;
  height: 45px;
  text-align: left;
  width: 235px;
  border: 3px solid white;
  padding-left: 10px;
}

/* Autocomplete --------------------------------------------------------------*/

.AUTOCOMPLETE {
  width: 100%;
}

.AUTOCOMPLETE #new_tag_input {
  border-color: white;
  margin-left: 10px;
  margin-right: 20px;
  height: 40px;
  width: 220px;
  border: 3px solid white;
  padding-left: 10px;
}

.TAGINPUTAREA .top_row_container {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.TAGINPUTAREA .tags_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 40px;
}

/* Select One ----------------------------------------------------------------*/

.SELECTONE {
  width: 100%;
  display: flex;
  justify-content: center;
}

.SELECTONE .tags_container {
  width: 80%;
  min-height: 50px;
  display: flex;
  justify-content: space-around;
}

.SELECTONE .option_tag {
  flex-grow: 1;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}



.SELECTONE .option_tag_selected {
  flex-grow: 1;
  border: 1px solid white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
}

.SELECTONE .text {
  margin: 0px;
}
