/*
  - Keeps track of colors for use throughout website
  -> import Colors.css into <App/> so it can reach all components
*/


/* General Colors ------------------------------------------------------------*/

.white_txt { color: white !important; }
.white_bg { background-color: white !important; }
.white_hover_bg:hover { background-color: white !important; }
.white_border { border-color: white !important; }

/* Twitch Colors -------------------------------------------------------------*/


/* Primary Colors -> (Light Purple, Dark Purple) */

.TWITCH_COLORS .primary_color_1_txt { color: #bb9bf7 !important; }

.TWITCH_COLORS .primary_color_2_txt { color: #8E5DE8 !important; }
.TWITCH_COLORS .primary_color_2_bg { background-color: #8E5DE8 !important; }
.TWITCH_COLORS .primary_color_2_border { border-color: #8E5DE8 !important;}
.TWITCH_COLORS .primary_color_2_hover_txt:hover { color: #8E5DE8 !important; }
.TWITCH_COLORS .primary_color_2_hover_bg:hover {background-color: #8E5DE8 !important; }
.TWITCH_COLORS .primary_color_2_hover_border:hover { border-color: #8E5DE8 !important;}

.TWITCH_COLORS .primary_color_3_txt { color: #4b367c !important; }
.TWITCH_COLORS .primary_color_3_bg { background-color: #4b367c !important; }
.TWITCH_COLORS .primary_color_3_border { border-color: #4b367c !important; }
.TWITCH_COLORS .primary_color_3_hover_txt:hover { color: #4b367c !important; }
.TWITCH_COLORS .primary_color_3_hover_bg:hover { background-color: #4b367c !important; }
.TWITCH_COLORS .primary_color_3_hover_border:hover { border-color: #4b367c !important; }

.TWITCH_COLORS .primary_color_4_txt { color: #2e293c !important; }
.TWITCH_COLORS .primary_color_4_bg { background-color: #2e293c !important; }
.TWITCH_COLORS .primary_color_4_border { border-color: #2e293c !important; }
.TWITCH_COLORS .primary_color_4_hover_txt:hover { color: #2e293c !important; }
.TWITCH_COLORS .primary_color_4_focus_border { border-color: #2e293c !important; }

/* color: 26232d */
.TWITCH_COLORS .primary_color_4_5_bg { background-color: #19171c !important; }

.TWITCH_COLORS .primary_color_5_txt { color: #19171c !important; }
.TWITCH_COLORS .primary_color_5_bg { background-color: #19171c !important; }
.TWITCH_COLORS .primary_color_5_border { border-color: #19171c !important; }
.TWITCH_COLORS .primary_color_5_hover_txt:hover { color: #19171c !important; }
.TWITCH_COLORS .primary_color_5_hover_bg:hover { background-color: #19171c !important; }

/* Primary Highlights ->  (White, Dark Gray)*/
.TWITCH_COLORS .primary_highlight_1_txt { color: white !important; }
.TWITCH_COLORS .primary_highlight_1_bg { background-color: white !important; }
.TWITCH_COLORS .primary_highlight_1_border { border-color: white !important; }
.TWITCH_COLORS .primary_highlight_1_hover_border:hover { border-color: white !important; }
.TWITCH_COLORS .primary_highlight_1_hover_txt:hover { color: white !important; }
.TWITCH_COLORS .primary_highlight_1_hover_bg:hover { background-color: white !important; }

.TWITCH_COLORS .primary_highlight_2_5_bg { background-color: #efefef !important; }
.TWITCH_COLORS .primary_highlight_2_5_border { border-color: #efefef !important; }

.TWITCH_COLORS .primary_highlight_2_txt { color: #cccccc !important; }
.TWITCH_COLORS .primary_highlight_2_border { border-color: #cccccc !important; }
.TWITCH_COLORS .primary_highlight_2_bg { background-color: #cccccc !important; }
.TWITCH_COLORS .primary_highlight_2_hover_bg:hover { background-color: #cccccc !important; }

.TWITCH_COLORS .primary_highlight_3_txt { color: #8e8e8e !important; }
.TWITCH_COLORS .primary_highlight_3_hover_txt:hover { color: #8e8e8e !important; }
.TWITCH_COLORS .primary_highlight_3_bg { background-color: #8e8e8e !important; }
.TWITCH_COLORS .primary_highlight_3_border { border-color: #8e8e8e !important; }


/* Gradients! */
.TWITCH_COLORS .transparent_to_opaque_primary_color_3_bg {
  background-image: linear-gradient(to right, rgba(75,54,124,0), rgba(75,54,124,1));
}

/* Youtube Colors ------------------------------------------------------------*/

.YOUTUBE_COLORS .primary_color_2_txt { color: #EB6C6C !important; }
.YOUTUBE_COLORS .primary_color_2_bg { background-color: #EB6C6C !important; }
.YOUTUBE_COLORS .primary_color_2_hover_txt:hover { color: #EB6C6C !important; }

.YOUTUBE_COLORS .primary_color_3_txt { color: #ff0000 !important; }
.YOUTUBE_COLORS .primary_color_3_bg { background-color: #ff0000 !important; }
.YOUTUBE_COLORS .primary_color_3_hover_txt:hover { color: #ff0000 !important; }

.YOUTUBE_COLORS .primary_color_4_txt { color: #630000 !important; }
.YOUTUBE_COLORS .primary_color_4_bg { background-color: #630000 !important; }
.YOUTUBE_COLORS .primary_color_4_hover_txt:hover { color: #630000 !important; }

.YOUTUBE_COLORS .primary_color_5_txt { color: #380606 !important; }
.YOUTUBE_COLORS .primary_color_5_bg { background-color: #380606 !important; }
.YOUTUBE_COLORS .primary_color_5_hover_txt:hover { color: #380606 !important; }
