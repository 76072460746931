.SEARCHRESULTSPAGE {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.SEARCHRESULTSPAGE .results_left_container {
  width: 1000px;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.SEARCHRESULTSPAGE .streamer_profile_container {
  height: calc(100% - 20px);
  padding: 20px 20px 0px 20px;
  width: calc(100% - 40px);
}



.SEARCHRESULTSPAGE .results_right_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.SEARCHRESULTSPAGE .streamer_cards_container {
  flex-grow: 1;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
}

.SEARCHRESULTSPAGE #search_again_row {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SEARCHRESULTSPAGE #search_again_button {
  height: 40px;
  margin-left: 50px;
  width: 60%;
  background-color: inherit;
  border: 1px solid white;
  border-radius: 3px;
}


.SEARCHRESULTSPAGE #about_search_results_container {
  height: 80%;
  width: 80%;
  margin-left: 10%;
  margin-top: 10%;
  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#about_search_results_container .filters_row {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#about_search_results_container #num_results_title {
  font-size: 2.5em;
}

#about_search_results_container #click_description {
  font-size: 1.8em;
}
