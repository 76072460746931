.STREAMERPROFILE {
  height: 100%;
  width: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
}


.STREAMERPROFILE .top_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 300px;
}

.STREAMERPROFILE .top_container .top_left_space_column {
  height: 100%;
  width: 50px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}

.STREAMERPROFILE .top_container .profile_picture_column {
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
}

.STREAMERPROFILE .top_container .about_streamer_column {
  height: 100%;
  width: calc(100% - 50px - 200px);
  display: flex;
  flex-direction: column;
}


.STREAMERPROFILE #profile_picture {
  height: 200px;
  width: 200px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.STREAMERPROFILE .above_profile_picture_space {
  height: 50px;
}

.STREAMERPROFILE .top_left_top_color {
  height: 190px;
}

.STREAMERPROFILE .about_streamer_top_row {
  height: 190px;
  width: 100%;
}

.STREAMERPROFILE .username_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.STREAMERPROFILE #username {
  font-size: 3.5em;
  margin-left: 10px;
  margin-top: 30px;
}

.STREAMERPROFILE #twitch_logo {
  height: 40px;
  width: 40px;
  margin-right: 30px;
  margin-top: 40px;
}

.STREAMERPROFILE .text {
  margin: 0px;
}

.STREAMERPROFILE #bio {
  text-align: left;
  margin-left: 10px;
  padding-right: 30px;
  font-size: 1.1em;
}

.STREAMERPROFILE .about_streamer_bottom_row {
  width: 100%;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
}

.STREAMERPROFILE .streamer_stats_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.STREAMERPROFILE .stat_text {
  margin: 5px;
  margin-top: 10px;
  width: 20%;
  font-size: 1.3em;
}


/* At a Glance -------------------------------------------------------------- */

.STREAMERPROFILE #at_a_glance_container {
  display: flex;
  width: 100%;
  border-top: 0px solid white;
  padding-top: 5px;
  margin-bottom: 20px;
  flex-shrink: 0;
}

.STREAMERPROFILE .flex_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.STREAMERPROFILE .flex_row_with_wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.STREAMERPROFILE .vertical_boundry {
  height: calc(100% - 40px);
  margin-top: 20px;
  width: 1px;
  margin-left: 10px;
}

.STREAMERPROFILE .title {
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.STREAMERPROFILE .release_date_text {
  margin: 0px;
  text-align: left;
  padding-left: 120px;
}


/* Related to Search ---------------------------------------------------------*/

.STREAMERPROFILE .section_container {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.STREAMERPROFILE .section_title {
  margin: 10px 0px 0px 10px;
  font-size: 2.5em;
  text-align: left;
}


.STREAMERPROFILE .game_container {
  width: 160px;
  border-radius: 5px;
  margin: 10px;
  flex-shrink: 0;
  padding-bottom: 5px;
}

.STREAMERPROFILE #game_img {
  width: 100%;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.STREAMERPROFILE .game_row {
  margin: 0px;
  border-radius: 5px;
}

.STREAMERPROFILE .game_text {
  margin: 0px;
}

.STREAMERPROFILE .secondary_title {
  text-align: left;
  margin: 10px 0px 0px 10px;
}

.STREAMERPROFILE .parameters_row {
  width: calc(100% - 20px);
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.STREAMERPROFILE .parameter_container {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  height: 40px;
  align-items: center;
  min-width: 100px;
  cursor: pointer;
}

.STREAMERPROFILE .parameter_container_border {
  padding: 3px;
  margin: 5px;
  border-radius: 5px;
}

.STREAMERPROFILE .parameter_text {
  margin: 0px;
}

.STREAMERPROFILE .parameter_spacing {
  height: 100%;
  width: 20px;
}

.STREAMERPROFILE .spacing_row_40px {
  height: 40px;
  width: 100%;
}
