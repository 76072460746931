.NAVBAR {
  height: calc(100% - 1px);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}


.NAVBAR .link {
  margin: 0px 20px 0px 20px;
}

.NAVBAR .links-container {
  margin-right: 20px;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NAVBAR #io_logo {
  height: 30px;
  margin-left: 10px;
}

.NAVBAR #twitter_logo {
  height: 50px;
}
