#DISPLAYGAMES {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 30px;
}

#DISPLAYGAMES .row {
  width: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
}
#DISPLAYGAMES #title {
  margin: 15px 0px 10px 10px;
  font-size: 2.5em;
}

#DISPLAYGAMES #close_button {
  background-color: none;
  font-size: 2.5em;
  padding: 0px;
  margin-right: 20px;
  border: none;
}

#DISPLAYGAMES .filter_bar_container {
  width: 80%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#DISPLAYGAMES #filter_input {
  margin-left: 20px;
  border: none;
  width: 70%;
}

#DISPLAYGAMES #search_icon {
  font-size: 2.2em;
  margin: 0px 10px 0px 0px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}


#DISPLAYGAMES #games_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

#DISPLAYGAMES .game_container {
  width: 160px;
  border-radius: 5px;
  margin: 10px;
  flex-shrink: 0;
  padding-bottom: 5px;
}

#DISPLAYGAMES #game_img {
  width: 100%;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

#DISPLAYGAMES .game_row {
  margin: 0px;
  border-radius: 5px;
}

#DISPLAYGAMES .game_text {
  margin: 0px;
}

#DISPLAYGAMES #expand_button {
  margin-top: 20px;
  border: 0px;
  padding: 10px 30px 10px 30px;
  border-radius: 5px;
}
