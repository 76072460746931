.App {
  text-align: center;
}

.App_Fullpage_Container {
  height: 100vh;
  width: 100vw;
}

.App_Navbar_Container {
  width: 100%;
  height: 55px;
}



.App_Body_Container {
  width: 100%;
  height: calc(100% - 55px);
}


h1, h2, p, a {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}

h3 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

input, button {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  font-size: 1.4em;
}


.TEST_CONTAINER {
  width: 900px;
}
