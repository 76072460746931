.SEARCHPARAMETERSPAGE {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column_container {
  width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
}


.SEARCHPARAMETERSPAGE #search_button {
  width: 400px;
  height: 60px;
  border: 2px solid white;
  background: none;
  margin-top: 20px;
  font-size: 1.5em;
}

.SEARCHPARAMETERSPAGE #search_button:hover {
  background-color: white;
}

.SEARCHPARAMETERSPAGE .search_row_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 80px;
}


.SEARCHPARAMETERSPAGE #search_parameters_container {
  height: calc(100% - 80px - 10px - 1px);
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SEARCHPARAMETERSPAGE .boundary_line {
  height: 1px;
  width: 100%;
  background-color: white;
  margin-top: 10px;
}

.SEARCHPARAMETERSPAGE .scroll_container_outer {
  min-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: hidden;
}

.SEARCHPARAMETERSPAGE .scroll_container_inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 50px;
  width: 100%;
}


.column_row {
  width: 1200px;
}

.SEARCHPARAMETERSPAGE .parameters_container {
  width: 100%;
  min-width: 1200px;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.SEARCHPARAMETERSPAGE .category_title {
  font-size: 3em;
}

.SEARCHPARAMETERSPAGE .sub_category_title {
  font-size: 2em;
  margin: 0px;
  margin-bottom: 5px;
}

.SEARCHPARAMETERSPAGE .parameter_title {
  font-size: 2em;
  margin: 0px;
}

.SEARCHPARAMETERSPAGE .filters_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
}

.SEARCHPARAMETERSPAGE .filters_column {
  width: 46%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.SEARCHPARAMETERSPAGE #bottom_margin {
  height: 1500px;
  flex-shrink: 0;
  width: 100%;
}

.SEARCHPARAMETERSPAGE #star_filter_explanation {
  width: 100%;
  text-align: center;
}

.SEARCHPARAMETERSPAGE #filters_explanation_row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
}

.SEARCHPARAMETERSPAGE #filters_explanation_container {
  width: 900px;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  align-items: center;
}

.SEARCHPARAMETERSPAGE .tag_container {
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}

.SEARCHPARAMETERSPAGE #star {
  font-size: 2.0em;
  margin: 0px;
}

.SEARCHPARAMETERSPAGE #x {
  font-size: 1.7em;
  margin: 0px 10px 0px 10px;
}
