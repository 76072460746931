.TOPTABLE {
  width: 100%;
  height: 100%;
}


.TOPTABLE .row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.TOPTABLE .left_spacing_container {
  width: 50px;
}

.TOPTABLE .item_container {
  width: 170px;
  padding: 15px 30px 0px 0px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin-left: 15px;
}

.TOPTABLE .item_name {
  width: 150px;
  text-align: left;
}

.TOPTABLE .title {
  margin: 0px;
}

.TOPTABLE #expand_button {
  border: none;
  padding: 5px 10px 5px 10px;
  width: 120px;
  margin-bottom: 20px;
}
