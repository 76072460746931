.OPTIONALINPUT {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.OPTIONALINPUT .left_container {
  display: flex;
}

.OPTIONALINPUT #checkmark_button {
  width: 40px;
  height: 40px;
  border: 1px solid white;
  font-size: 3.5em;
  text-align: center;
  padding: 0px;
  line-height: 0;
}


.OPTIONALINPUT .title_container {
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  border: 1px solid white;
  display: flex;
  min-width: 160px;
}

.OPTIONALINPUT .rounded_corners {
  border-radius: 4px;
}

.OPTIONALINPUT #title {
  margin: 0px;
  font-size: 1.2em;
  align-self: center;
}


.OPTIONALINPUT #input {
  text-align: right;
  padding-right: 20px;
}
